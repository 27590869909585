<template>
  <div class="paper-thumbnail">
    <div class="paper-thumbnail__image-wrapper">
      <img v-for="(imagepath, index) in imagepaths" :key="index" :src="imagepath" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaperThumbnail',
  props: {
    imagepaths: {
      type: Array
    }
  }
}
</script>
