<template>
  <div class="research-thesis-scene" >
    <div id="three-research-thesis-scene-canvas">
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { EventBus } from '@/js/EventBus.js'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

export default {
  name: 'ResearchThesisScene',
  data () {
    return {
      sceneCanvas: null,
      scene: null,
      camera: null,
      renderer: null,
      //controls: null,
      headObj: null,
    }
  },
  mounted () {
    EventBus.$on('research-mouse-move', this.mouseMove)

    /* **************
       BASIC SETUP
    ************** */

    this.sceneCanvas = document.getElementById('three-research-thesis-scene-canvas')
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(
      25,
      this.sceneCanvas.getBoundingClientRect().width / this.sceneCanvas.getBoundingClientRect().height,
      0.1,
      30
    )
    this.camera.position.set(0, 0, 4)
    
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance"
    })
    this.renderer.outputEncoding = THREE.sRGBEncoding

    //this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    //this.controls.addEventListener('change', this.animateThreeJs )

    //this.controls.minPolarAngle = 0.5
    //this.controls.maxPolarAngle = 3
    //this.controls.minAzimuthAngle = -2
    //this.controls.maxAzimuthAngle = 2
    //this.controls.enablePan  = false

    this.renderer.setSize(this.sceneCanvas.offsetWidth, this.sceneCanvas.offsetHeight)
    this.renderer.setClearColor("#0d0d0d")
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
    this.renderer.shadowMapSoft = true
    this.renderer.shadowMap.autoUpdate = false
    this.renderer.shadowMap.needsUpdate = true

    this.sceneCanvas.append(this.renderer.domElement)

    // lighting
    let ambientLight = new THREE.AmbientLight (0xffc17a, 0.9)
    this.scene.add(ambientLight)

    let light = new THREE.PointLight(0xffcca0, 0.5, 100)
    light.position.set(10, 5, 5)
    this.scene.add(light)

    // Loading the 3DMM Alex object
    let objLoader = new OBJLoader()
    objLoader.load('/images/research/alex.obj', (obj) => {
      this.headObj = obj
      this.scene.add(this.headObj)
      this.animateThreeJs()
    })
  },


  methods: {
    animateThreeJs () {
      this.renderer.render(this.scene, this.camera)
      //this.renderer.shadowMap.needsUpdate = true
    },

    mouseMove () {
      let pos = this.getMousePosition(event)
      this.headObj.lookAt((pos.x*2)+2, pos.y*3, 3)
      this.animateThreeJs()
    },

    getMousePosition (event) {
      const mouse = new THREE.Vector2()
      const raycaster = new THREE.Raycaster()
      // Normalize mouse coordinates to range [-1, 1
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
      // Set raycaster's origin at camera position
      raycaster.setFromCamera(mouse, this.camera)
      // Calculate intersection point with z=0 plane
      const intersection = new THREE.Vector3()
      raycaster.ray.intersectPlane(new THREE.Plane(new THREE.Vector3(0, 0, 1), 0), intersection)
      return intersection
    }
  }
}
</script>
