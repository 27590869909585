<template>
  <div class="showcase-page showcase-ods">
    <div class="site-wrapper__content no-padding-sm">
      <div class="container page-component">
        <h1>Research Project</h1>
        <span class="chip">🇺🇸 English Language</span>
        <h3 class="color-blue">Realtime 3D Human Mesh Reconstruction<br>from a single 2D Camera View</h3>

        <a class="btn" target="_blank" href="/cloud/Realtime_3D_Reconstruction_from_a_single_2D_Camera_View.pdf">Paper</a>
        &nbsp;
        <a class="btn" target="_blank" href="https://github.com/aalolexx/awt-pj-ws2425-3d-realtime-reconstruction">Github</a>
        <br><br>
        <p>Abstract</p>
        <p>
          Computer Vision has experienced remarkable advances in recent years, with both traditional and especially machine learning-based methods 
          showing notable promise. Classic approaches, guided by carefully crafted heuristics and assumptions, run efficiently, but tend to
          introduce artifacts that stem from these domain-specific priors. Meanwhile, contemporary deep learning methods generate 
          highly detailed and visually convincing results, yet they often demand substantial computation and processing time.<br><br>
          In this work, we explore a purely visual-based approach from 2D image input for realtime 3D human mesh reconstruction. 
          We present a pipepline that takes a webcam image and streams a coarsely constructed mesh to Unity.
        </p>
        <br><br>
        
        <video width="100%" controls>
            <source src="/cloud/showcase_video_ods.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShowcaseODS',
  mounted () {
    document.body.classList.remove('loading')
  }
}
</script>
